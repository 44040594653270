import { Map } from 'mapbox-gl';

function getFeatureForPoint(
    map: Map,
    layerIds: string[],
    point: mapboxgl.Point
) {
    const layersExist = layerIds.every(
        layerId => map.getLayer(layerId) != null
    );
    if (!layersExist) {
        return null;
    }

    const features = map.queryRenderedFeatures(point, {
        layers: layerIds
    });
    return features.length ? features[0] : null;
}

export default getFeatureForPoint;
