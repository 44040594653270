import React, { Dispatch, ReactNode, useEffect, useMemo, useRef } from 'react';
import {
    EventData,
    Layer,
    Map,
    MapLayerMouseEvent,
    Sources,
    Popup as MapboxPopup
} from 'mapbox-gl';
import { useStreetSegments } from '../../layers/routes/api';
import getFeatureForPoint from '../../common/get-feature-for-point';
import { DataStep, useRoutesDataSteps } from '../../layers/routes/data-steps';
import {
    RIDESHED_AREAS_SOURCE_KEY,
    RIDESHEDS_LAYER_KEY,
    useAreaRideshedLayers
} from '../../layers/routes/layers';
import { RoutesPopup } from '../../layers/ridesheds/popup';
import { RideshedActions, RideshedState } from './reducer';
import { DispatchMapOptions } from 'page-explore';
import {
    useAreaRideshedsInfo,
    useAreaRideshed
} from 'page-explore/layers/ridesheds/api';
import {
    RideshedFeature,
    useRideshedSources
} from 'page-explore/layers/ridesheds/sources';
import { LocalDate } from 'utils/date-tools';
import { MultiPolygon } from 'geojson';
import { getDataColor } from 'utils/helpers';

interface RideshedEffects {
    layers: Layer[];
    sources: Sources;
    popup: ReactNode;
    dataSteps: DataStep[] | null;
    loading: boolean;
}

export type RideshedAreaOfInterest = {
    id: string;
    name: string;
    geometry: MultiPolygon;
};

export type RideshedAreaData = RideshedAreaOfInterest[];

export type RideshedAreaFeature = GeoJSON.Feature<
    GeoJSON.MultiPolygon,
    {
        color: string;
        id: string;
        name: string;
    }
>;

export const getRideshedAreasSource = (areas: RideshedAreaData) => {
    const source = {
        type: 'geojson' as const,
        cluster: false,
        data: {
            type: 'FeatureCollection' as const,
            features: [
                ...areas.map((aoi, i) => ({
                    type: 'Feature',
                    id: i,
                    geometry: aoi.geometry,
                    properties: {
                        color: getDataColor(i + 2).backgroundColor,
                        id: aoi.id,
                        name: aoi.name
                    }
                }))
            ] as RideshedAreaFeature[]
        }
    };
    return source;
};

/**
 * Builds and returns the mapbox layers and sources for the current set of
 * street segments and ridesheds.
 *
 * `sources` contains our rideshed data to display on the map, including relevant
 * properties such as the name and trip count for each segment.
 * `layers` tells mapbox how to display and style the data provided by the sources.
 */
export const useRideshedEffects = (
    map: Map | null,
    dispatchMapOptions: DispatchMapOptions,
    state: RideshedState,
    dispatch: Dispatch<RideshedActions>
): RideshedEffects => {
    const [
        areaRideshedsInfo,
        isAreaRideshedsInfoLoading
    ] = useAreaRideshedsInfo();

    useEffect(() => {
        dispatch({
            type: 'SET_AREA_RIDESHEDS_INFO',
            payload: {
                areaRideshedsInfo: areaRideshedsInfo
            }
        });
    }, [dispatch, areaRideshedsInfo]);

    const dataPeriod: [LocalDate, LocalDate] = useMemo(() => {
        if (!state.selectedAreaRideshedInfo) {
            return [
                LocalDate.fromDate(new Date()),
                LocalDate.fromDate(new Date())
            ];
        }

        const startDate = LocalDate.fromDateString(
            state.selectedAreaRideshedInfo.startDate
        );
        const endDate = LocalDate.fromDateString(
            state.selectedAreaRideshedInfo.endDate
        );
        return [startDate, endDate];
    }, [state.selectedAreaRideshedInfo]);

    const streetSegments = useStreetSegments();

    useEffect(() => {
        dispatch({
            type: 'SET_STREET_SEGMENTS',
            payload: {
                streetSegments
            }
        });
    }, [dispatch, streetSegments]);

    const [rideshed, isRideshedLoading] = useAreaRideshed({
        areaRideshedId: state.selectedAreaRideshedInfo
            ? state.selectedAreaRideshedInfo.id
            : null
    });

    useEffect(() => {
        dispatch({
            type: 'SET_SELECTED_AREA_RIDESHED_DATA',
            payload: {
                rideshed
            }
        });
    }, [dispatch, rideshed]);

    const areas =
        state.selectedAreaRideshedInfo?.areas.map(area => {
            // transform all geometryJSON into MultiPolygons
            return {
                ...area,
                geometry: JSON.parse(area.geometryJson) as MultiPolygon
            };
        }) || [];

    const sources = useRideshedSources(streetSegments, rideshed);
    sources[RIDESHED_AREAS_SOURCE_KEY] = getRideshedAreasSource(areas);

    // reuse routes data steps for ridesheds
    const dataSteps = useRoutesDataSteps(rideshed);

    // this will create layers for the heatmap data, the street segments, and the rideshed areas.
    const layers = useAreaRideshedLayers(
        dataSteps,
        state.selectedStreetSegment
    );

    const popupRef = useRef<HTMLDivElement>(null);

    const loading =
        isAreaRideshedsInfoLoading ||
        isRideshedLoading ||
        streetSegments == null;

    // Set selected street segment on click
    useEffect(() => {
        if (map) {
            const listener = (event: MapLayerMouseEvent & EventData) => {
                //Attach a listener that detects features under the mouse
                const feature = getFeatureForPoint(
                    map,
                    [RIDESHEDS_LAYER_KEY],
                    event.point
                );

                if (!feature || !feature.properties || !feature.geometry) {
                    return;
                }

                dispatch({
                    type: 'SET_SELECTED_STREET_SEGMENT',
                    payload: {
                        selectedStreetSegment: {
                            id: feature.properties.id,
                            lngLatClicked: event.lngLat,
                            properties: feature.properties as RideshedFeature['properties'],
                            geometry: feature.geometry as RideshedFeature['geometry']
                        }
                    }
                });
            };
            map.on('click', listener);
            return () => {
                map.off('click', listener);
            };
        }
    }, [map, dispatch]);

    // Create the PopUp
    useEffect(() => {
        if (popupRef.current && map && state.selectedStreetSegment) {
            const copy = popupRef.current.cloneNode(true);
            const popup = new MapboxPopup()
                .setLngLat(state.selectedStreetSegment.lngLatClicked)
                .setDOMContent(copy)
                .addTo(map)
                .once('close', () => {
                    dispatch({
                        type: 'SET_SELECTED_STREET_SEGMENT',
                        payload: {
                            selectedStreetSegment: null
                        }
                    });
                });

            dispatch({
                type: 'SET_SELECTED_STREET_SEGMENT',
                payload: {
                    selectedStreetSegment: state.selectedStreetSegment
                }
            });

            return () => {
                popup.remove();
            };
        }
    }, [map, state.selectedStreetSegment, dispatch]);

    // change the cursor on hover
    useEffect(() => {
        if (map) {
            const listener = ({ point }) => {
                let feature = getFeatureForPoint(
                    map,
                    [RIDESHEDS_LAYER_KEY],
                    point
                );
                if (feature) {
                    map.getCanvas().style.cursor = 'pointer';
                } else {
                    map.getCanvas().style.cursor = '';
                }
            };
            map.on('mousemove', listener);
            return () => {
                map.off('mousemove', listener);
            };
        }
    }, [map]);

    // handle setting the loading spinner
    useEffect(() => {
        let loadingTimeout: NodeJS.Timeout | undefined;
        if (loading === true) {
            // if we're going to show the spinner, set a short timeout. This
            // gives quick updates a chance to go through without showing a loading
            // spinner, helping prevent a quick flash on the screen.
            loadingTimeout = setTimeout(() => {
                dispatchMapOptions({
                    type: 'set loading data',
                    value: true
                });
            }, 100);
        } else {
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        }

        return () => {
            // make sure to clear our timeout and remove the loading spinner
            // when we clean up
            if (loadingTimeout) clearTimeout(loadingTimeout);
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        };
    }, [dispatchMapOptions, loading]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set sources',
            payload: {
                dataType: 'ridesheds',
                sources
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set sources',
                payload: { dataType: 'ridesheds', sources: {} }
            });
    }, [dispatchMapOptions, sources]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set layers',
            payload: {
                dataType: 'ridesheds',
                layers
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'ridesheds', layers: [] }
            });
    }, [dispatchMapOptions, layers]);

    return {
        layers,
        sources,
        popup: (
            <RoutesPopup
                ref={popupRef}
                dataPeriod={dataPeriod}
                streetSegment={state.selectedStreetSegment}
            />
        ),
        dataSteps,
        loading
    };
};
