import {
    AreaRideshedType,
    RideshedData
} from 'page-explore/layers/ridesheds/api';
import { SelectedStreetSegment } from 'page-explore/layers/routes/types';
import { StreetSegments } from '../../layers/routes/api';
import { Day, VehicleClass } from 'graphql.g';
import { OperatorName } from 'constants/operators';

export type RideshedActions =
    | {
          type: 'SET_STREET_SEGMENTS';
          payload: {
              streetSegments: StreetSegments | null;
          };
      }
    | {
          type: 'SET_AREA_RIDESHEDS_INFO';
          payload: {
              areaRideshedsInfo: RideshedMetadata[];
          };
      }
    | {
          type: 'SET_SELECTED_AREA_RIDESHED_DATA';
          payload: {
              rideshed: RideshedData | null;
          };
      }
    | {
          type: 'SET_SELECTED_AREA_RIDESHED_INFO';
          payload: {
              selectedAreaRideshedInfo: RideshedMetadata;
          };
      }
    | {
          type: 'SET_SELECTED_STREET_SEGMENT';
          payload: {
              selectedStreetSegment: SelectedStreetSegment | null;
          };
      }
    | {
          type: 'SET_LOADING';
          payload: { loading: boolean };
      };

export interface RideshedMetadata {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    days: Day[];
    status: string;
    areas: {
        id: string;
        name: string;
        slug: string;
        geometryJson: string;
    }[];
    operators: {
        id: string;
        name: OperatorName;
    }[];
    vehicleClasses: VehicleClass[];
    type: AreaRideshedType;
}

export interface RideshedState {
    streetSegments: StreetSegments;
    areaRideshedsInfo: RideshedMetadata[];
    selectedAreaRideshedData: RideshedData;
    selectedAreaRideshedInfo: RideshedMetadata | null;
    loading: boolean;
    selectedStreetSegment: SelectedStreetSegment | null;
}

export const rideshedInitialState: RideshedState = {
    streetSegments: {},
    areaRideshedsInfo: [],
    selectedAreaRideshedData: {
        referenceCounts: {},
        totalTripCount: 0,
        matchedTripCount: 0
    },
    selectedAreaRideshedInfo: null,
    loading: false,
    selectedStreetSegment: null
};

export function rideshedReducer(
    state: Readonly<RideshedState>,
    action: RideshedActions
): Readonly<RideshedState> {
    switch (action.type) {
        case 'SET_STREET_SEGMENTS':
            return {
                ...state,
                streetSegments: action.payload.streetSegments ?? {}
            };
        case 'SET_AREA_RIDESHEDS_INFO':
            return {
                ...state,
                areaRideshedsInfo: action.payload.areaRideshedsInfo
            };
        case 'SET_SELECTED_AREA_RIDESHED_DATA':
            return {
                ...state,
                selectedAreaRideshedData: action.payload.rideshed ?? {
                    referenceCounts: {},
                    totalTripCount: 0,
                    matchedTripCount: 0
                }
            };
        case 'SET_SELECTED_AREA_RIDESHED_INFO':
            return {
                ...state,
                selectedAreaRideshedInfo:
                    action.payload.selectedAreaRideshedInfo
            };
        case 'SET_SELECTED_STREET_SEGMENT':
            return {
                ...state,
                selectedStreetSegment: action.payload.selectedStreetSegment
            };
        case 'SET_LOADING': {
            return {
                ...state,
                loading: action.payload.loading
            };
        }
        default:
            return state;
    }
}
