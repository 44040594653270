/**
 * An error for when a context-using hook is used when the proper provider hasn't been supplied above it in the component tree.
 */
export class ContextError extends Error {
    constructor(
        /** The name of the context. Ie, "Intl" should have an "IntlProvider" and a "useIntl" hook. */
        contextName: string
    ) {
        super(
            `You must have a <${contextName}Provider> above this component in the tree in order to use ${contextName}`
        );
    }
}
