export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]: Maybe<T[SubKey]> };

/* eslint-disable import/first */
import geojson from 'geojson';
import { OperatorName } from 'constants/operators';

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** {"hex": string, "count": number}[] */
    AggregatedTripsHexData: any;
    /** [x1, y1, x2, y2] */
    Bounds: [number, number, number, number];
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: string;
    /** ["2021-01-01", "2021-03-22"] */
    DateRange: [string, string];
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: string;
    Geometry: geojson.Geometry;
    /** [x, y] integer pairs where x is the hour of the day and y is the number of available vehicles */
    HourlyDeploymentCount: [number, number];
    /** The name of an operator: Bird, Lyft, Lime, etc. */
    OperatorName: OperatorName;
    /** Data that the consumer should not rely on the shape of. */
    RawData: any;
    /**
     * The `Time` scalar type represents a Time value as
     * specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Time: any;
};

export enum AggregatedReportPeriod {
    weekly = 'weekly',
    monthly = 'monthly'
}

export enum AggregationTripDataType {
    trip_starts = 'trip_starts',
    trip_ends = 'trip_ends'
}

/** An enumeration. */
export enum AnalyzeDataAggregateUnit {
    minutes = 'minutes',
    miles = 'miles',
    kilometers = 'kilometers',
    trips = 'trips',
    vehicles = 'vehicles',
    trips_per_hour = 'trips_per_hour',
    trips_per_day = 'trips_per_day',
    trips_per_day_by_hour = 'trips_per_day_by_hour',
    trips_per_vehicle = 'trips_per_vehicle',
    seconds = 'seconds',
    hours = 'hours',
    co2_tons = 'co2_tons'
}

/** An enumeration. */
export enum AnalyzeDataXUnit {
    date = 'date',
    minutes = 'minutes',
    miles = 'miles',
    kilometers = 'kilometers',
    hour = 'hour',
    day_and_hour = 'day_and_hour',
    weekday = 'weekday'
}

/** An enumeration. */
export enum AnalyzeDataYUnit {
    vehicles = 'vehicles',
    trips = 'trips',
    trips_per_vehicle = 'trips_per_vehicle',
    seconds = 'seconds',
    hours = 'hours',
    miles = 'miles',
    kilometers = 'kilometers',
    minutes = 'minutes',
    co2_tons = 'co2_tons'
}

export type CreateAreaOfInterestInput = {
    mapviewId: Scalars['ID'];
    name: Scalars['String'];
    geometry: Scalars['Geometry'];
    operators?: InputMaybe<Array<Scalars['ID']>>;
    sharedWithOperators?: InputMaybe<Scalars['Boolean']>;
    priority?: InputMaybe<Scalars['Int']>;
    tableViewOnly?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePolicyInput = {
    mapviewId: Scalars['ID'];
    name: Scalars['String'];
    description: Scalars['String'];
    operatorIds: Array<Scalars['ID']>;
    startDate: Scalars['Date'];
    endDate?: InputMaybe<Scalars['Date']>;
    policyType: PolicyType;
    rules: Array<InputMaybe<CreatePolicyRuleInput>>;
    minDeploymentUnits?: InputMaybe<MinDeploymentUnits>;
};

export type CreatePolicyRuleInput = {
    name: Scalars['String'];
    areaIds: Array<InputMaybe<Scalars['ID']>>;
    ruleType: RuleType;
    vehicleClasses?: InputMaybe<Array<VehicleClass>>;
    days?: InputMaybe<Array<Day>>;
    rateAmount?: InputMaybe<Scalars['Int']>;
    maximum?: InputMaybe<Scalars['Int']>;
    minimum?: InputMaybe<Scalars['Int']>;
    summaryStatistic?: InputMaybe<Scalars['String']>;
    startTime?: InputMaybe<Scalars['Time']>;
    endTime?: InputMaybe<Scalars['Time']>;
};

export type CreateTripFilterInput = {
    vehicleClass: VehicleClass;
    minDistance?: InputMaybe<Scalars['Int']>;
    maxDistance?: InputMaybe<Scalars['Int']>;
    minDuration?: InputMaybe<Scalars['Int']>;
    maxDuration?: InputMaybe<Scalars['Int']>;
};

export enum CustomerStageType {
    Demo = 'Demo',
    Trial = 'Trial',
    StageOne = 'StageOne',
    StageTwo = 'StageTwo',
    StageThree = 'StageThree'
}

export enum Day {
    sunday = 'sunday',
    saturday = 'saturday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday'
}

export type DeletePolicyInput = {
    mapviewId: Scalars['ID'];
    policyId: Scalars['String'];
};

export type EditAreaInput = {
    areaId: Scalars['ID'];
    priority?: InputMaybe<Scalars['Int']>;
    tableViewOnly?: InputMaybe<Scalars['Boolean']>;
    agencyOnly?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
};

export type EditPolicyInput = {
    policyId: Scalars['ID'];
    mapviewId: Scalars['ID'];
    name: Scalars['String'];
    description: Scalars['String'];
    operatorIds: Array<Scalars['ID']>;
    startDate: Scalars['Date'];
    endDate?: InputMaybe<Scalars['Date']>;
    isPublic: Scalars['Boolean'];
};

export type EditStreetSegmentInput = {
    geometryId: Scalars['ID'];
    mapviewId: Scalars['ID'];
    name: Scalars['String'];
};

export enum EventLocationsDataType {
    trip_starts = 'trip_starts',
    trip_ends = 'trip_ends',
    deployments = 'deployments'
}

export enum FeatureFlaggedFeature {
    invoices = 'invoices',
    policies = 'policies',
    policy_api = 'policy_api',
    self_serve_aois = 'self_serve_aois',
    self_serve_policies = 'self_serve_policies',
    spec_audit_reports = 'spec_audit_reports',
    internal_rr_tiles = 'internal_rr_tiles',
    policy_email = 'policy_email',
    regional_dashboard = 'regional_dashboard',
    custom_datasets = 'custom_datasets',
    custom_overlays = 'custom_overlays',
    trip_events = 'trip_events',
    project_atlas = 'project_atlas',
    rewind = 'rewind',
    prohibit_routes_computation = 'prohibit_routes_computation',
    store_trip_street_segments = 'store_trip_street_segments',
    area_ridesheds = 'area_ridesheds'
}

/**
 * The possible states of incidents that may be requested
 *
 * Represents a binary concept of incidents: either they are closed
 * (resolved) or open (unresolved)
 */
export enum IncidentState {
    resolved = 'resolved',
    unresolved = 'unresolved'
}

/** An enumeration. */
export enum MeasurementUnitSystemType {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL'
}

export enum MinDeploymentUnits {
    num_devices = 'num_devices',
    percentage = 'percentage'
}

/** The possible OSM road class values for Street Segment road classifications. */
export enum OSMRoadClass {
    motorway = 'motorway',
    trunk = 'trunk',
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    residential = 'residential',
    unclassified = 'unclassified',
    service = 'service',
    other = 'other'
}

export enum OperatorStageType {
    DataSharing = 'DataSharing',
    Certified = 'Certified',
    Preferred = 'Preferred'
}

export enum OrganizationType {
    Operator = 'Operator',
    Agency = 'Agency'
}

export enum PolicyType {
    trip_start = 'trip_start',
    trip_start_or_stop = 'trip_start_or_stop',
    trip_start_xor_stop = 'trip_start_xor_stop',
    trip_start_and_stop = 'trip_start_and_stop',
    trip_end = 'trip_end',
    right_of_way = 'right_of_way',
    vehicle_cap = 'vehicle_cap',
    vehicle_cap_percentage = 'vehicle_cap_percentage',
    no_parking_zone = 'no_parking_zone',
    minimum_deployment = 'minimum_deployment',
    minimum_deployment_percentage = 'minimum_deployment_percentage',
    speed_limit = 'speed_limit',
    no_ride_zone = 'no_ride_zone',
    max_idle_time = 'max_idle_time',
    mean_active = 'mean_active',
    provider_deployment_cap = 'provider_deployment_cap',
    provider_deployment_minimum = 'provider_deployment_minimum'
}

export type ReplaceAreaOfInterestInput = {
    previousVersionId: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    geometry?: InputMaybe<Scalars['Geometry']>;
    operators?: InputMaybe<Array<Scalars['ID']>>;
    sharedWithOperators?: InputMaybe<Scalars['Boolean']>;
    priority?: InputMaybe<Scalars['Int']>;
    tableViewOnly?: InputMaybe<Scalars['Boolean']>;
};

export enum RuleType {
    rate = 'rate',
    count = 'count',
    speed = 'speed',
    time = 'time',
    user = 'user'
}

export enum UserRoleType {
    Administrator = 'Administrator',
    User = 'User'
}

/**
 * What kind of vehicle it is. Distinct from the much more granular concept
 * of an mds vehicle_type.
 */
export enum VehicleClass {
    bike = 'bike',
    ebike = 'ebike',
    scooter = 'scooter',
    moped = 'moped',
    car = 'car',
    other = 'other',
    cargo_bike = 'cargo_bike',
    cargo_ebike = 'cargo_ebike',
    seated_scooter = 'seated_scooter',
    delivery_robot = 'delivery_robot',
    bus = 'bus',
    truck = 'truck',
    motorcycle = 'motorcycle',
    pedestrian = 'pedestrian'
}

export type AreaDetailArgs = Exact<{
    mapview: Scalars['String'];
    area: Scalars['String'];
}>;

export type AreaDetailData = {
    mapView: {
        id: string;
        areaOfInterest: {
            geometryJson: string;
            dataRanges: Array<[string, string]>;
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            policyRules: Array<
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
            >;
            operators: Array<{ id: string; name: OperatorName }>;
        } | null;
    };
};

export type AreaGeometryArgs = Exact<{
    mapview: Scalars['String'];
    area: Scalars['String'];
}>;

export type AreaGeometryData = {
    mapView: {
        id: string;
        areaOfInterest: {
            geometryJson: string;
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            operators: Array<{ id: string; name: OperatorName }>;
        } | null;
    };
};

export type AreaNamesArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    includeArchived?: InputMaybe<Scalars['Boolean']>;
}>;

export type AreaNamesData = {
    mapView: {
        id: string;
        areasOfInterest: Array<{
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            operators: Array<{ id: string; name: OperatorName }>;
        }>;
    };
};

export type OrganizationInfo_AgencyOrganization_Fragment = {
    __typename: 'AgencyOrganization';
    id: string;
    name: string;
    locale: string;
    mapViews: Array<{
        id: string;
        name: string;
        slug: string;
        hasAtLeastOneMdsFeed: boolean;
    }>;
};

export type OrganizationInfo_OperatorOrganization_Fragment = {
    __typename: 'OperatorOrganization';
    id: string;
    name: string;
    locale: string;
    operatorInfo: { id: string; name: OperatorName };
    mapViews: Array<{
        id: string;
        name: string;
        slug: string;
        hasAtLeastOneMdsFeed: boolean;
    }>;
};

export type OrganizationInfoFragment =
    | OrganizationInfo_AgencyOrganization_Fragment
    | OrganizationInfo_OperatorOrganization_Fragment;

export type OrganizationsArgs = Exact<{ [key: string]: never }>;

export type OrganizationsData = {
    organizations: Array<
        | {
              __typename: 'AgencyOrganization';
              id: string;
              name: string;
              locale: string;
              mapViews: Array<{
                  id: string;
                  name: string;
                  slug: string;
                  hasAtLeastOneMdsFeed: boolean;
              }>;
          }
        | {
              __typename: 'OperatorOrganization';
              id: string;
              name: string;
              locale: string;
              operatorInfo: { id: string; name: OperatorName };
              mapViews: Array<{
                  id: string;
                  name: string;
                  slug: string;
                  hasAtLeastOneMdsFeed: boolean;
              }>;
          }
    >;
};

export type JoinOrganizationMutationArgs = Exact<{
    org: Scalars['ID'];
    role: UserRoleType;
}>;

export type JoinOrganizationResult = {
    joinOrganization: {
        user: {
            id: number;
            orgAuth: {
                id: string;
                role: UserRoleType;
                organization:
                    | {
                          __typename: 'AgencyOrganization';
                          id: string;
                          name: string;
                          locale: string;
                          mapViews: Array<{
                              id: string;
                              name: string;
                              slug: string;
                              hasAtLeastOneMdsFeed: boolean;
                          }>;
                      }
                    | {
                          __typename: 'OperatorOrganization';
                          id: string;
                          name: string;
                          locale: string;
                          operatorInfo: { id: string; name: OperatorName };
                          mapViews: Array<{
                              id: string;
                              name: string;
                              slug: string;
                              hasAtLeastOneMdsFeed: boolean;
                          }>;
                      };
            } | null;
        };
    } | null;
};

export type MapViewVehicleClassesArgs = Exact<{
    slug: Scalars['String'];
}>;

export type MapViewVehicleClassesData = {
    mapView: { id: string; availableVehicleClasses: Array<VehicleClass> };
};

export type OperatorVehicleClassesArgs = Exact<{
    slug: Scalars['String'];
    operator: Scalars['ID'];
}>;

export type OperatorVehicleClassesData = {
    mapView: {
        id: string;
        operator: {
            id: string;
            availableVehicleClasses: Array<VehicleClass>;
        } | null;
    };
};

export type AnalyzeChartArgs = Exact<{
    slug: Scalars['String'];
    operatorId?: InputMaybe<Scalars['ID']>;
    areaId?: InputMaybe<Scalars['ID']>;
    start: Scalars['Date'];
    end: Scalars['Date'];
    metric: Scalars['String'];
    vehicleClass?: InputMaybe<VehicleClass>;
    tripDataType?: InputMaybe<AggregationTripDataType>;
}>;

export type AnalyzeChartData = {
    mapView: {
        id: string;
        analyzeData: {
            groupBy: string;
            tripDataType: AggregationTripDataType | null;
            startDate: string;
            endDate: string;
            xUnit: AnalyzeDataXUnit;
            yUnit: AnalyzeDataYUnit;
            aggregateUnit: AnalyzeDataAggregateUnit;
            totalCountUnit: AnalyzeDataAggregateUnit | null;
            dataLine: {
                group: string;
                median: number | null;
                average: number | null;
                sumTotal: number | null;
                totalCount: number | null;
                dataPoints: Array<{
                    xValue: string;
                    yValue: number | null;
                    percentageOfTotal: number | null;
                }>;
            };
            metricDefinition: {
                displayName: string;
                description: string;
                purposeDescription: string;
            };
        } | null;
    };
};

export type LatestFinalizedReportDateArgs = Exact<{
    slug: Scalars['String'];
}>;

export type LatestFinalizedReportDateData = {
    mapView: { id: string; latestFinalizedReportDate: string | null };
};

export type EarliestReportDateArgs = Exact<{
    slug: Scalars['String'];
}>;

export type EarliestReportDateData = {
    mapView: { id: string; earliestReportDate: string | null };
};

export type ArchiveAreaMutationArgs = Exact<{
    areaId: Scalars['ID'];
    archived: Scalars['Boolean'];
}>;

export type ArchiveAreaResult = {
    editAreaOfInterest: {
        area: {
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            operators: Array<{ id: string; name: OperatorName }>;
        } | null;
    } | null;
};

export type ExistingAreaNamesArgs = Exact<{
    slug: Scalars['String'];
}>;

export type ExistingAreaNamesData = {
    mapView: {
        areasOfInterest: Array<{ id: string; slug: string; name: string }>;
    };
};

export type RuleRelatedToArea_PolicyMaximumRule_Fragment = {
    id: string;
    name: string;
    policy: { id: string; name: string };
};

export type RuleRelatedToArea_PolicyMinDeploymentRule_Fragment = {
    id: string;
    name: string;
    policy: { id: string; name: string };
};

export type RuleRelatedToArea_PolicyRateRule_Fragment = {
    id: string;
    name: string;
    policy: { id: string; name: string };
};

export type RuleRelatedToArea_PolicyVehicleCapRule_Fragment = {
    id: string;
    name: string;
    policy: { id: string; name: string };
};

export type RuleRelatedToAreaFragment =
    | RuleRelatedToArea_PolicyMaximumRule_Fragment
    | RuleRelatedToArea_PolicyMinDeploymentRule_Fragment
    | RuleRelatedToArea_PolicyRateRule_Fragment
    | RuleRelatedToArea_PolicyVehicleCapRule_Fragment;

export type AreaDetailsFragment = {
    geometryJson: string;
    dataRanges: Array<[string, string]>;
    id: string;
    name: string;
    slug: string;
    priority: number;
    tableViewOnly: boolean;
    isMunicipalityBoundary: boolean;
    operatorIds: Array<string>;
    archivedAt: string | null;
    sharedWithOperators: boolean;
    localCreatedDate: string;
    modified: string;
    policyRules: Array<
        | { id: string; name: string; policy: { id: string; name: string } }
        | { id: string; name: string; policy: { id: string; name: string } }
        | { id: string; name: string; policy: { id: string; name: string } }
        | { id: string; name: string; policy: { id: string; name: string } }
    >;
    operators: Array<{ id: string; name: OperatorName }>;
};

export type UpdateAreaBySlugCacheFragment = {
    id: string;
    areaOfInterest: { id: string; slug: string } | null;
};

export type RenameAreaMutationArgs = Exact<{
    area: EditAreaInput;
}>;

export type RenameAreaResult = {
    editAreaOfInterest: {
        area: { id: string; name: string; slug: string } | null;
    } | null;
};

export type EditAreaMutationArgs = Exact<{
    area: EditAreaInput;
}>;

export type EditAreaResult = {
    editAreaOfInterest: {
        area: {
            id: string;
            priority: number;
            agencyOnly: boolean;
            sharedWithOperators: boolean;
        } | null;
    } | null;
};

export type MunicipalityBoundaryArgs = Exact<{
    slug: Scalars['String'];
}>;

export type MunicipalityBoundaryData = {
    mapView: { municipalityGeometry: geojson.Geometry | null };
};

export type CreateAreaMutationArgs = Exact<{
    area: CreateAreaOfInterestInput;
}>;

export type CreateAreaResult = {
    createAreaOfInterest: {
        area: {
            geometryJson: string;
            dataRanges: Array<[string, string]>;
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            policyRules: Array<
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
                | {
                      id: string;
                      name: string;
                      policy: { id: string; name: string };
                  }
            >;
            operators: Array<{ id: string; name: OperatorName }>;
        };
    } | null;
};

export type CustomDatasetsArgs = Exact<{
    mapviewId: Scalars['ID'];
}>;

export type CustomDatasetsData = {
    mapViewById: {
        id: string;
        customDatasets: Array<{ id: string; name: string; hexColor: string }>;
    };
};

export type CustomDatasetArgs = Exact<{
    mapviewId: Scalars['ID'];
    customDatasetId: Scalars['ID'];
}>;

export type CustomDatasetData = {
    mapViewById: {
        id: string;
        customDataset: {
            id: string;
            name: string;
            hexColor: string;
            latitudeColumnIndex: number;
            longitudeColumnIndex: number;
            csvFile: string;
        } | null;
    };
};

export type DeleteCustomDatasetMutationArgs = Exact<{
    customDatasetId: Scalars['ID'];
}>;

export type DeleteCustomDatasetResult = {
    deleteCustomDataset: { customDatasetId: string | null } | null;
};

export type AreasOfInterestGeometryArgs = Exact<{
    id: Scalars['ID'];
}>;

export type AreasOfInterestGeometryData = {
    mapViewById: {
        id: string;
        areasOfInterest: Array<{
            geometryJson: string;
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            operators: Array<{ id: string; name: OperatorName }>;
        }>;
    };
};

export type RealtimeVehicleWithHistoryArgs = Exact<{
    id: Scalars['ID'];
}>;

export type RealtimeVehicleWithHistoryData = {
    mapViewById: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            operatorId: string;
            mdsVehicleHistory: string | null;
        }>;
    };
};

export type CurrentVehiclePositionsArgs = Exact<{
    id: Scalars['ID'];
}>;

export type CurrentVehiclePositionsData = {
    mapViewById: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            operatorId: string;
            mdsVehiclePositions: string | null;
            mdsIngestionTimestamp: string | null;
        }>;
    };
};

export type AreaRideshedArgs = Exact<{
    slug: Scalars['String'];
    areaRideshedId: Scalars['ID'];
}>;

export type AreaRideshedData = {
    mapView: {
        id: string;
        areaRideshed: {
            referenceCounts: string;
            totalTripCount: number;
            matchedTripCount: number;
        } | null;
    };
};

export type AreaRideshedsInfoArgs = Exact<{
    slug: Scalars['String'];
}>;

export type AreaRideshedsInfoData = {
    mapView: {
        id: string;
        areaRidesheds: Array<{
            id: string;
            name: string | null;
            startDate: string;
            endDate: string;
            startTime: any | null;
            endTime: any | null;
            days: Array<Day> | null;
            status: string;
            vehicleClasses: Array<VehicleClass>;
            type: string;
            areas: Array<{
                id: string;
                name: string;
                slug: string;
                geometryJson: string;
            }>;
            operators: Array<{ id: string; name: OperatorName }> | null;
        } | null> | null;
    };
};

export type StreetSegmentsArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type StreetSegmentsData = {
    mapView: { id: string; streetSegmentsJson: string };
};

export type AggregatedTripResultsArgs = Exact<{
    slug: Scalars['String'];
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    hours?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type AggregatedTripResultsData = {
    mapView: {
        id: string;
        aggregatedTripRoutes: {
            referenceCountData: string;
            totalTripCount: number;
            matchedTripCount: number;
        };
    };
};

export type AvailableRoutesDatesArgs = Exact<{
    id: Scalars['ID'];
}>;

export type AvailableRoutesDatesData = {
    mapViewById: {
        id: string;
        earliestAggregatedTripRouteDate: string | null;
        latestAggregatedTripRouteDate: string | null;
    };
};

export type CurbEventsArgs = Exact<{
    slug: Scalars['String'];
    dataType: EventLocationsDataType;
    dates: Scalars['DateRange'];
    vehicleClass?: InputMaybe<VehicleClass>;
    operatorId?: InputMaybe<Scalars['ID']>;
}>;

export type CurbEventsData = { mapView: { eventLocationsJson: string } };

export type AggregatedTripsArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    metric?: InputMaybe<Scalars['String']>;
    startDate?: InputMaybe<Scalars['Date']>;
    endDate?: InputMaybe<Scalars['Date']>;
    startHour?: InputMaybe<Scalars['Int']>;
    endHour?: InputMaybe<Scalars['Int']>;
    inHex?: InputMaybe<Scalars['ID']>;
}>;

export type AggregatedTripsData = {
    mapView: { id: string; aggregatedTrips: { data: any } };
};

export type MapViewHexIdsArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type MapViewHexIdsData = {
    mapView: { id: string; hexIds: Array<string> };
};

export type OriginDestinationPrivacyThresholdArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type OriginDestinationPrivacyThresholdData = {
    mapView: { id: string; originDestinationPrivacyThreshold: number };
};

export type EarliestTripsMetricsDateArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type EarliestTripsMetricsDateData = {
    mapView: { id: string; earliestTripsMetricDate: string | null };
};

export type PoliciesByAoiArgs = Exact<{
    mapviewId: Scalars['ID'];
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
}>;

export type PoliciesByAoiData = {
    mapViewById: {
        areasOfInterest: Array<{
            id: string;
            name: string;
            geometryJson: string;
            isMunicipalityBoundary: boolean;
            policyRules: Array<
                | {
                      policy: {
                          id: string;
                          name: string;
                          description: string;
                          hasEnded: boolean;
                          policyType: PolicyType;
                          startDate: string;
                          endDate: string | null;
                          policyApiData: any;
                          isPublic: boolean;
                          metric: {
                              startDate: string;
                              endDate: string;
                              ruleTarget: number;
                              summedOperatorData: {
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              };
                              data: Array<{
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              }>;
                          } | null;
                          operators: Array<{ id: string; name: OperatorName }>;
                          rules: Array<
                              | {
                                    __typename: 'PolicyMaximumRule';
                                    name: string;
                                    maximum: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyMinDeploymentRule';
                                    name: string;
                                    minimum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyRateRule';
                                    name: string;
                                    rateAmount: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyVehicleCapRule';
                                    name: string;
                                    maximum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                          >;
                      };
                  }
                | {
                      policy: {
                          id: string;
                          name: string;
                          description: string;
                          hasEnded: boolean;
                          policyType: PolicyType;
                          startDate: string;
                          endDate: string | null;
                          policyApiData: any;
                          isPublic: boolean;
                          metric: {
                              startDate: string;
                              endDate: string;
                              ruleTarget: number;
                              summedOperatorData: {
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              };
                              data: Array<{
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              }>;
                          } | null;
                          operators: Array<{ id: string; name: OperatorName }>;
                          rules: Array<
                              | {
                                    __typename: 'PolicyMaximumRule';
                                    name: string;
                                    maximum: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyMinDeploymentRule';
                                    name: string;
                                    minimum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyRateRule';
                                    name: string;
                                    rateAmount: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyVehicleCapRule';
                                    name: string;
                                    maximum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                          >;
                      };
                  }
                | {
                      policy: {
                          id: string;
                          name: string;
                          description: string;
                          hasEnded: boolean;
                          policyType: PolicyType;
                          startDate: string;
                          endDate: string | null;
                          policyApiData: any;
                          isPublic: boolean;
                          metric: {
                              startDate: string;
                              endDate: string;
                              ruleTarget: number;
                              summedOperatorData: {
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              };
                              data: Array<{
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              }>;
                          } | null;
                          operators: Array<{ id: string; name: OperatorName }>;
                          rules: Array<
                              | {
                                    __typename: 'PolicyMaximumRule';
                                    name: string;
                                    maximum: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyMinDeploymentRule';
                                    name: string;
                                    minimum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyRateRule';
                                    name: string;
                                    rateAmount: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyVehicleCapRule';
                                    name: string;
                                    maximum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                          >;
                      };
                  }
                | {
                      policy: {
                          id: string;
                          name: string;
                          description: string;
                          hasEnded: boolean;
                          policyType: PolicyType;
                          startDate: string;
                          endDate: string | null;
                          policyApiData: any;
                          isPublic: boolean;
                          metric: {
                              startDate: string;
                              endDate: string;
                              ruleTarget: number;
                              summedOperatorData: {
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              };
                              data: Array<{
                                  numDaysInCompliance: number;
                                  numDaysInPeriod: number;
                                  percentCompliance: number;
                                  percentChangeInCompliance: number | null;
                                  complianceIsAcceptable: boolean;
                                  operator: { name: OperatorName } | null;
                                  points: Array<{
                                      xValue: string;
                                      yValue: number | null;
                                  }>;
                              }>;
                          } | null;
                          operators: Array<{ id: string; name: OperatorName }>;
                          rules: Array<
                              | {
                                    __typename: 'PolicyMaximumRule';
                                    name: string;
                                    maximum: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyMinDeploymentRule';
                                    name: string;
                                    minimum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyRateRule';
                                    name: string;
                                    rateAmount: number;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                              | {
                                    __typename: 'PolicyVehicleCapRule';
                                    name: string;
                                    maximum: number;
                                    summaryStatistic: string;
                                    id: string;
                                    ruleType: RuleType;
                                    vehicleClasses: Array<VehicleClass>;
                                    days: Array<Day>;
                                    startTime: any | null;
                                    endTime: any | null;
                                    areas: Array<{
                                        id: string;
                                        name: string;
                                        slug: string;
                                    }>;
                                }
                          >;
                      };
                  }
            >;
        }>;
    };
};

export type InvoiceTotalArgs = Exact<{
    slug: Scalars['String'];
    date: Scalars['Date'];
    operatorSlug: Scalars['String'];
}>;

export type InvoiceTotalData = {
    mapView: {
        id: string;
        invoice:
            | {
                  __typename: 'FailedInvoice';
                  id: string;
                  operator: { name: OperatorName; id: string };
              }
            | {
                  __typename: 'IssuedInvoice';
                  total: number;
                  id: string;
                  fees: Array<{
                      unitCount: number;
                      amount: number;
                      rule: { name: string; rateAmount: number };
                  }>;
                  operator: { name: OperatorName; id: string };
              }
            | {
                  __typename: 'PendingInvoice';
                  id: string;
                  operator: { name: OperatorName; id: string };
              }
            | null;
    };
};

export type FeeRulesArgs = Exact<{
    slug: Scalars['String'];
}>;

export type FeeRulesData = {
    mapView: {
        id: string;
        activeFees: Array<{ id: string; name: string; rateAmount: number }>;
    };
};

export type InvoiceListArgs = Exact<{
    slug: Scalars['String'];
}>;

export type InvoiceListData = {
    mapView: {
        id: string;
        invoices: Array<
            | {
                  __typename: 'FailedInvoice';
                  reason: string | null;
                  id: string;
                  startDate: string;
                  operator: { id: string; name: OperatorName; slug: string };
              }
            | {
                  __typename: 'IssuedInvoice';
                  total: number;
                  id: string;
                  startDate: string;
                  operator: { id: string; name: OperatorName; slug: string };
              }
            | {
                  __typename: 'PendingInvoice';
                  expectedIssueDate: string | null;
                  id: string;
                  startDate: string;
                  operator: { id: string; name: OperatorName; slug: string };
              }
        >;
    };
};

export type IncidentReportsArgs = Exact<{
    slug: Scalars['String'];
    state: IncidentState;
}>;

export type IncidentReportsData = {
    incidentReports: Array<{
        id: string;
        title: string;
        components: Array<string>;
        operator: { id: string; name: OperatorName } | null;
        statuses: Array<{
            id: string;
            state: string;
            date: string;
            notes: string | null;
        }>;
    } | null> | null;
};

export type FeedStatusesArgs = Exact<{
    mapviewSlug: Scalars['String'];
}>;

export type FeedStatusesData = {
    feedStatuses: Array<{
        sourceId: string;
        statusChangeStatus: boolean;
        tripsStatus: boolean;
        mdsVersion: string | null;
        operator: { id: string; name: OperatorName };
    } | null> | null;
};

export type TripFilterInfoFragment = {
    id: string;
    vehicleClass: VehicleClass;
    minDuration: number | null;
    maxDuration: number | null;
    minDistance: number | null;
    maxDistance: number | null;
};

export type GetTripFiltersArgs = Exact<{
    slug: Scalars['String'];
}>;

export type GetTripFiltersData = {
    mapView: {
        id: string;
        tripFilters: Array<{
            id: string;
            vehicleClass: VehicleClass;
            minDuration: number | null;
            maxDuration: number | null;
            minDistance: number | null;
            maxDistance: number | null;
        }>;
    };
};

export type TripFilterWithExcludedPercentFragment = {
    excludedPercent: number | null;
    id: string;
    vehicleClass: VehicleClass;
    minDuration: number | null;
    maxDuration: number | null;
    minDistance: number | null;
    maxDistance: number | null;
    excludedPercentByOperator: Array<{
        excludedPercent: number;
        operator: { id: string; name: OperatorName };
    }> | null;
};

export type PreviewTripFilterArgs = Exact<{
    slug: Scalars['String'];
    filter: CreateTripFilterInput;
}>;

export type PreviewTripFilterData = {
    mapView: { id: string; tripFilterPreviewForVehicleClass: number | null };
};

export type CreateFilterMutationArgs = Exact<{
    id: Scalars['ID'];
    slug: Scalars['String'];
    filters: Array<CreateTripFilterInput> | CreateTripFilterInput;
}>;

export type CreateFilterResult = {
    createTripFilter: {
        mapView: {
            id: string;
            tripFilters: Array<{
                excludedPercent: number | null;
                id: string;
                vehicleClass: VehicleClass;
                minDuration: number | null;
                maxDuration: number | null;
                minDistance: number | null;
                maxDistance: number | null;
                excludedPercentByOperator: Array<{
                    excludedPercent: number;
                    operator: { id: string; name: OperatorName };
                }> | null;
            }>;
        } | null;
    } | null;
};

export type EarliestFilteredTripDateArgs = Exact<{
    slug: Scalars['String'];
}>;

export type EarliestFilteredTripDateData = {
    mapView: { earliestFilteredTripDate: string | null };
};

export type GetTripFiltersWithExcludedPercentArgs = Exact<{
    slug: Scalars['String'];
}>;

export type GetTripFiltersWithExcludedPercentData = {
    mapView: {
        id: string;
        tripFilters: Array<{
            excludedPercent: number | null;
            id: string;
            vehicleClass: VehicleClass;
            minDuration: number | null;
            maxDuration: number | null;
            minDistance: number | null;
            maxDistance: number | null;
            excludedPercentByOperator: Array<{
                excludedPercent: number;
                operator: { id: string; name: OperatorName };
            }> | null;
        }>;
    };
};

export type ModeShiftDataArgs = Exact<{
    slug: Scalars['String'];
    start_date: Scalars['Date'];
    end_date: Scalars['Date'];
}>;

export type ModeShiftDataData = {
    mapViewBySlug: {
        id: string;
        modeShift: {
            id: string;
            modeShiftPercentages: Array<{
                vehicleType: VehicleClass;
                percentage: number;
            }>;
        } | null;
    } | null;
};

export type ModeShiftMetricDataArgs = Exact<{
    slug: Scalars['String'];
    start_date: Scalars['Date'];
    end_date: Scalars['Date'];
    metric: Scalars['String'];
}>;

export type ModeShiftMetricDataData = {
    mapViewBySlug: {
        id: string;
        modeShift: {
            id: string;
            modeShiftData: {
                totalByVehicleClass: Array<{
                    vehicleType: VehicleClass;
                    total: number | null;
                }>;
                analyzeData: {
                    groupBy: string;
                    tripDataType: AggregationTripDataType | null;
                    startDate: string;
                    endDate: string;
                    xUnit: AnalyzeDataXUnit;
                    yUnit: AnalyzeDataYUnit;
                    aggregateUnit: AnalyzeDataAggregateUnit;
                    totalCountUnit: AnalyzeDataAggregateUnit | null;
                    dataLine: {
                        group: string;
                        median: number | null;
                        average: number | null;
                        sumTotal: number | null;
                        totalCount: number | null;
                        dataPoints: Array<{
                            xValue: string;
                            yValue: number | null;
                            percentageOfTotal: number | null;
                        }>;
                    };
                    metricDefinition: {
                        displayName: string;
                        description: string;
                        purposeDescription: string;
                    };
                } | null;
            } | null;
        } | null;
    } | null;
};

export type PolicyDetailsFragment = {
    id: string;
    name: string;
    description: string;
    hasEnded: boolean;
    policyType: PolicyType;
    startDate: string;
    endDate: string | null;
    policyApiData: any;
    isPublic: boolean;
    operators: Array<{ id: string; name: OperatorName }>;
    rules: Array<
        | {
              __typename: 'PolicyMaximumRule';
              name: string;
              maximum: number;
              id: string;
              ruleType: RuleType;
              vehicleClasses: Array<VehicleClass>;
              days: Array<Day>;
              startTime: any | null;
              endTime: any | null;
              areas: Array<{ id: string; name: string; slug: string }>;
          }
        | {
              __typename: 'PolicyMinDeploymentRule';
              name: string;
              minimum: number;
              summaryStatistic: string;
              id: string;
              ruleType: RuleType;
              vehicleClasses: Array<VehicleClass>;
              days: Array<Day>;
              startTime: any | null;
              endTime: any | null;
              areas: Array<{ id: string; name: string; slug: string }>;
          }
        | {
              __typename: 'PolicyRateRule';
              name: string;
              rateAmount: number;
              id: string;
              ruleType: RuleType;
              vehicleClasses: Array<VehicleClass>;
              days: Array<Day>;
              startTime: any | null;
              endTime: any | null;
              areas: Array<{ id: string; name: string; slug: string }>;
          }
        | {
              __typename: 'PolicyVehicleCapRule';
              name: string;
              maximum: number;
              summaryStatistic: string;
              id: string;
              ruleType: RuleType;
              vehicleClasses: Array<VehicleClass>;
              days: Array<Day>;
              startTime: any | null;
              endTime: any | null;
              areas: Array<{ id: string; name: string; slug: string }>;
          }
    >;
};

export type PolicyRuleDetails_PolicyMaximumRule_Fragment = {
    __typename: 'PolicyMaximumRule';
    name: string;
    maximum: number;
    id: string;
    ruleType: RuleType;
    vehicleClasses: Array<VehicleClass>;
    days: Array<Day>;
    startTime: any | null;
    endTime: any | null;
    areas: Array<{ id: string; name: string; slug: string }>;
};

export type PolicyRuleDetails_PolicyMinDeploymentRule_Fragment = {
    __typename: 'PolicyMinDeploymentRule';
    name: string;
    minimum: number;
    summaryStatistic: string;
    id: string;
    ruleType: RuleType;
    vehicleClasses: Array<VehicleClass>;
    days: Array<Day>;
    startTime: any | null;
    endTime: any | null;
    areas: Array<{ id: string; name: string; slug: string }>;
};

export type PolicyRuleDetails_PolicyRateRule_Fragment = {
    __typename: 'PolicyRateRule';
    name: string;
    rateAmount: number;
    id: string;
    ruleType: RuleType;
    vehicleClasses: Array<VehicleClass>;
    days: Array<Day>;
    startTime: any | null;
    endTime: any | null;
    areas: Array<{ id: string; name: string; slug: string }>;
};

export type PolicyRuleDetails_PolicyVehicleCapRule_Fragment = {
    __typename: 'PolicyVehicleCapRule';
    name: string;
    maximum: number;
    summaryStatistic: string;
    id: string;
    ruleType: RuleType;
    vehicleClasses: Array<VehicleClass>;
    days: Array<Day>;
    startTime: any | null;
    endTime: any | null;
    areas: Array<{ id: string; name: string; slug: string }>;
};

export type PolicyRuleDetailsFragment =
    | PolicyRuleDetails_PolicyMaximumRule_Fragment
    | PolicyRuleDetails_PolicyMinDeploymentRule_Fragment
    | PolicyRuleDetails_PolicyRateRule_Fragment
    | PolicyRuleDetails_PolicyVehicleCapRule_Fragment;

export type PolicyMetricDataLineFragment = {
    numDaysInCompliance: number;
    numDaysInPeriod: number;
    percentCompliance: number;
    percentChangeInCompliance: number | null;
    complianceIsAcceptable: boolean;
    operator: { name: OperatorName } | null;
    points: Array<{ xValue: string; yValue: number | null }>;
};

export type PolicyMetricFragment = {
    startDate: string;
    endDate: string;
    ruleTarget: number;
    summedOperatorData: {
        numDaysInCompliance: number;
        numDaysInPeriod: number;
        percentCompliance: number;
        percentChangeInCompliance: number | null;
        complianceIsAcceptable: boolean;
        operator: { name: OperatorName } | null;
        points: Array<{ xValue: string; yValue: number | null }>;
    };
    data: Array<{
        numDaysInCompliance: number;
        numDaysInPeriod: number;
        percentCompliance: number;
        percentChangeInCompliance: number | null;
        complianceIsAcceptable: boolean;
        operator: { name: OperatorName } | null;
        points: Array<{ xValue: string; yValue: number | null }>;
    }>;
};

export type DeletePolicyMutationArgs = Exact<{
    policy: DeletePolicyInput;
}>;

export type DeletePolicyResult = {
    deletePolicy: { policyId: string | null } | null;
};

export type EditPolicyMutationArgs = Exact<{
    policy: EditPolicyInput;
}>;

export type EditPolicyResult = {
    editPolicy: {
        policy: {
            id: string;
            name: string;
            description: string;
            startDate: string;
            endDate: string | null;
            isPublic: boolean;
            operators: Array<{ name: OperatorName }>;
        } | null;
    } | null;
};

export type CreatePolicyMutationArgs = Exact<{
    policy: CreatePolicyInput;
}>;

export type CreatePolicyResult = {
    createPolicy: {
        policy: {
            id: string;
            name: string;
            description: string;
            hasEnded: boolean;
            policyType: PolicyType;
            startDate: string;
            endDate: string | null;
            policyApiData: any;
            isPublic: boolean;
            operators: Array<{ id: string; name: OperatorName }>;
            rules: Array<
                | {
                      __typename: 'PolicyMaximumRule';
                      name: string;
                      maximum: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyMinDeploymentRule';
                      name: string;
                      minimum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyRateRule';
                      name: string;
                      rateAmount: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyVehicleCapRule';
                      name: string;
                      maximum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
            >;
        };
    } | null;
};

export type PoliciesWithMetricsArgs = Exact<{
    id: Scalars['ID'];
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
}>;

export type PoliciesWithMetricsData = {
    mapViewById: {
        id: string;
        policies: Array<{
            id: string;
            name: string;
            description: string;
            hasEnded: boolean;
            policyType: PolicyType;
            startDate: string;
            endDate: string | null;
            policyApiData: any;
            isPublic: boolean;
            metric: {
                startDate: string;
                endDate: string;
                ruleTarget: number;
                summedOperatorData: {
                    numDaysInCompliance: number;
                    numDaysInPeriod: number;
                    percentCompliance: number;
                    percentChangeInCompliance: number | null;
                    complianceIsAcceptable: boolean;
                    operator: { name: OperatorName } | null;
                    points: Array<{ xValue: string; yValue: number | null }>;
                };
                data: Array<{
                    numDaysInCompliance: number;
                    numDaysInPeriod: number;
                    percentCompliance: number;
                    percentChangeInCompliance: number | null;
                    complianceIsAcceptable: boolean;
                    operator: { name: OperatorName } | null;
                    points: Array<{ xValue: string; yValue: number | null }>;
                }>;
            } | null;
            operators: Array<{ id: string; name: OperatorName }>;
            rules: Array<
                | {
                      __typename: 'PolicyMaximumRule';
                      name: string;
                      maximum: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyMinDeploymentRule';
                      name: string;
                      minimum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyRateRule';
                      name: string;
                      rateAmount: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyVehicleCapRule';
                      name: string;
                      maximum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
            >;
        }>;
    };
};

export type PoliciesArgs = Exact<{
    id: Scalars['ID'];
}>;

export type PoliciesData = {
    mapViewById: {
        id: string;
        policies: Array<{
            id: string;
            name: string;
            description: string;
            hasEnded: boolean;
            policyType: PolicyType;
            startDate: string;
            endDate: string | null;
            policyApiData: any;
            isPublic: boolean;
            operators: Array<{ id: string; name: OperatorName }>;
            rules: Array<
                | {
                      __typename: 'PolicyMaximumRule';
                      name: string;
                      maximum: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyMinDeploymentRule';
                      name: string;
                      minimum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyRateRule';
                      name: string;
                      rateAmount: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyVehicleCapRule';
                      name: string;
                      maximum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
            >;
        }>;
        endedPolicies: Array<{
            id: string;
            name: string;
            description: string;
            hasEnded: boolean;
            policyType: PolicyType;
            startDate: string;
            endDate: string | null;
            policyApiData: any;
            isPublic: boolean;
            operators: Array<{ id: string; name: OperatorName }>;
            rules: Array<
                | {
                      __typename: 'PolicyMaximumRule';
                      name: string;
                      maximum: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyMinDeploymentRule';
                      name: string;
                      minimum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyRateRule';
                      name: string;
                      rateAmount: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyVehicleCapRule';
                      name: string;
                      maximum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
            >;
        }>;
    };
};

export type PolicyWithMetricsArgs = Exact<{
    mapviewId: Scalars['ID'];
    policyId: Scalars['ID'];
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
}>;

export type PolicyWithMetricsData = {
    mapViewById: {
        id: string;
        policy: {
            id: string;
            name: string;
            description: string;
            hasEnded: boolean;
            policyType: PolicyType;
            startDate: string;
            endDate: string | null;
            policyApiData: any;
            isPublic: boolean;
            metric: {
                startDate: string;
                endDate: string;
                ruleTarget: number;
                summedOperatorData: {
                    numDaysInCompliance: number;
                    numDaysInPeriod: number;
                    percentCompliance: number;
                    percentChangeInCompliance: number | null;
                    complianceIsAcceptable: boolean;
                    operator: { name: OperatorName } | null;
                    points: Array<{ xValue: string; yValue: number | null }>;
                };
                data: Array<{
                    numDaysInCompliance: number;
                    numDaysInPeriod: number;
                    percentCompliance: number;
                    percentChangeInCompliance: number | null;
                    complianceIsAcceptable: boolean;
                    operator: { name: OperatorName } | null;
                    points: Array<{ xValue: string; yValue: number | null }>;
                }>;
            } | null;
            operators: Array<{ id: string; name: OperatorName }>;
            rules: Array<
                | {
                      __typename: 'PolicyMaximumRule';
                      name: string;
                      maximum: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyMinDeploymentRule';
                      name: string;
                      minimum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyRateRule';
                      name: string;
                      rateAmount: number;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
                | {
                      __typename: 'PolicyVehicleCapRule';
                      name: string;
                      maximum: number;
                      summaryStatistic: string;
                      id: string;
                      ruleType: RuleType;
                      vehicleClasses: Array<VehicleClass>;
                      days: Array<Day>;
                      startTime: any | null;
                      endTime: any | null;
                      areas: Array<{ id: string; name: string; slug: string }>;
                  }
            >;
        } | null;
    };
};

export type RegionalEarliestReportDatesArgs = Exact<{ [key: string]: never }>;

export type RegionalEarliestReportDatesData = {
    mapViews: Array<{ earliestReportDate: string | null }>;
};

export type RegionalAnalyzeChartArgs = Exact<{
    operatorId?: InputMaybe<Scalars['ID']>;
    areaId?: InputMaybe<Scalars['ID']>;
    start: Scalars['Date'];
    end: Scalars['Date'];
    metric: Scalars['String'];
    vehicleClass?: InputMaybe<VehicleClass>;
    tripDataType?: InputMaybe<AggregationTripDataType>;
}>;

export type RegionalAnalyzeChartData = {
    mapViews: Array<{
        slug: string;
        name: string;
        id: string;
        analyzeData: {
            xUnit: AnalyzeDataXUnit;
            yUnit: AnalyzeDataYUnit;
            aggregateUnit: AnalyzeDataAggregateUnit;
            totalCountUnit: AnalyzeDataAggregateUnit | null;
            dataLine: {
                group: string;
                sumTotal: number | null;
                totalCount: number | null;
                dataPoints: Array<{
                    xValue: string;
                    yValue: number | null;
                    percentageOfTotal: number | null;
                }>;
            };
        } | null;
    }>;
};

export type RegionalAvailableVehicleClassesArgs = Exact<{
    [key: string]: never;
}>;

export type RegionalAvailableVehicleClassesData = {
    mapViews: Array<{
        id: string;
        availableVehicleClasses: Array<VehicleClass>;
    }>;
};

export type ReportMaxVehiclesFragment = {
    maxVehicleMetric: {
        maxAvailable: number;
        maxAvailableTime: string;
        maxUnavailable: number;
        maxUnavailableTime: string;
        maxParked: number;
        maxParkedTime: string;
    } | null;
};

export type ReportTripCountsFragment = {
    tripCounts: { startCount: number } | null;
};

export type ReportTripsPerVehicleFragment = {
    tripsPerVehicleMetric: { tripsPerVehicle: number | null } | null;
};

export type AoiInfoFragment = {
    id: string;
    name: string;
    slug: string;
    priority: number;
    tableViewOnly: boolean;
    isMunicipalityBoundary: boolean;
    operatorIds: Array<string>;
    archivedAt: string | null;
    sharedWithOperators: boolean;
    localCreatedDate: string;
    modified: string;
    operators: Array<{ id: string; name: OperatorName }>;
};

export type MdsAoiInfoFragment = {
    area: {
        id: string;
        name: string;
        slug: string;
        priority: number;
        tableViewOnly: boolean;
        isMunicipalityBoundary: boolean;
        operatorIds: Array<string>;
        archivedAt: string | null;
        sharedWithOperators: boolean;
        localCreatedDate: string;
        modified: string;
        operators: Array<{ id: string; name: OperatorName }>;
    };
};

export type MdsAoiTripCountsFragment = {
    tripEndCount: number | null;
    tripStartCount: number | null;
};

export type MdsAoiMorningDeploymentsFragment = {
    morningDeploymentCounts: {
        unavailableCount: number | null;
        availableCount: number | null;
        parkedCount: number | null;
    } | null;
};

export type AggregatedReportMaxVehiclesFragment = {
    maxVehicles: {
        maxAvailable: number;
        maxUnavailable: number;
        maxParked: number;
        averageMaxAvailable: number;
        averageMaxUnavailable: number;
        averageMaxParked: number;
    } | null;
};

export type AggregatedReportTripCountsFragment = {
    tripCounts: { startCount: number; averageStartCount: number } | null;
};

export type AggregatedReportTripsPerVehicleFragment = {
    tripsPerVehicle: { tripsPerVehicle: number | null } | null;
};

export type AggregatedReportAoiMetricsFragment = {
    aoiMetrics: Array<{
        tripsPerVehicle: number | null;
        totalMaxAvailable: number | null;
        area: {
            id: string;
            name: string;
            slug: string;
            priority: number;
            tableViewOnly: boolean;
            isMunicipalityBoundary: boolean;
            operatorIds: Array<string>;
            archivedAt: string | null;
            sharedWithOperators: boolean;
            localCreatedDate: string;
            modified: string;
            operators: Array<{ id: string; name: OperatorName }>;
        };
        tripCounts: {
            startCount: number;
            averageStartCount: number;
            endCount: number;
            averageEndCount: number;
        } | null;
        morningDeploymentCounts: {
            averageAvailableCount: number;
            averageUnavailableCount: number;
            averageParkedCount: number;
        } | null;
    }>;
};

export type UnfinalizedOperatorFragment = { id: string; name: OperatorName };

export type OperatorHasDailyReportArgs = Exact<{
    slug: Scalars['String'];
    date: Scalars['Date'];
}>;

export type OperatorHasDailyReportData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            report: {
                id: string;
                reportDate: string;
                aoiMetrics: Array<{
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                }> | null;
            } | null;
        }>;
    };
};

export type OperatorHasAggregatedReportArgs = Exact<{
    slug: Scalars['String'];
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
}>;

export type OperatorHasAggregatedReportData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            report: {
                id: string;
                reportDate: string;
                aoiMetrics: Array<{
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                }>;
            } | null;
        }>;
    };
};

export type AggregatedReportAreasArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type AggregatedReportAreasData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            operatorId: string;
            name: OperatorName;
            aggregatedReport: {
                aoiMetrics: Array<{
                    tripsPerVehicle: number | null;
                    totalMaxAvailable: number | null;
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                    tripCounts: {
                        startCount: number;
                        averageStartCount: number;
                        endCount: number;
                        averageEndCount: number;
                    } | null;
                    morningDeploymentCounts: {
                        averageAvailableCount: number;
                        averageUnavailableCount: number;
                        averageParkedCount: number;
                    } | null;
                }>;
            } | null;
        }>;
    };
};

export type SingleOperatorAggregatedAoiDataArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    operatorId?: InputMaybe<Scalars['ID']>;
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type SingleOperatorAggregatedAoiDataData = {
    mapView: {
        id: string;
        operator: {
            id: string;
            aggregatedReport: {
                aoiMetrics: Array<{
                    tripsPerVehicle: number | null;
                    totalMaxAvailable: number | null;
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                    tripCounts: {
                        startCount: number;
                        averageStartCount: number;
                        endCount: number;
                        averageEndCount: number;
                    } | null;
                    morningDeploymentCounts: {
                        averageAvailableCount: number;
                        averageUnavailableCount: number;
                        averageParkedCount: number;
                    } | null;
                }>;
            } | null;
        } | null;
    };
};

export type OperatorAggregatedReportArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    operatorId?: InputMaybe<Scalars['ID']>;
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type OperatorAggregatedReportData = {
    mapView: {
        id: string;
        operator: {
            id: string;
            aggregatedReport: {
                finalized: boolean;
                maxVehicles: {
                    maxAvailable: number;
                    maxUnavailable: number;
                    maxParked: number;
                    averageMaxAvailable: number;
                    averageMaxUnavailable: number;
                    averageMaxParked: number;
                } | null;
                tripCounts: {
                    startCount: number;
                    averageStartCount: number;
                } | null;
                tripsPerVehicle: { tripsPerVehicle: number | null } | null;
            } | null;
        } | null;
    };
};

export type AggregatedReportOverviewVehiclesArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type AggregatedReportOverviewVehiclesData = {
    mapView: {
        id: string;
        aggregatedOverviewReport: {
            id: string;
            reportStartDate: string;
            aggregatedReports: Array<{
                id: string;
                operator: { name: OperatorName };
                maxVehicles: {
                    maxAvailable: number;
                    maxUnavailable: number;
                    maxParked: number;
                    averageMaxAvailable: number;
                    averageMaxUnavailable: number;
                    averageMaxParked: number;
                } | null;
            }> | null;
        } | null;
    };
};

export type AggregatedReportOverviewTripsArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    period: AggregatedReportPeriod;
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type AggregatedReportOverviewTripsData = {
    mapView: {
        id: string;
        aggregatedOverviewReport: {
            id: string;
            reportStartDate: string;
            aggregatedReports: Array<{
                id: string;
                operator: { name: OperatorName };
                tripCounts: {
                    startCount: number;
                    averageStartCount: number;
                } | null;
                tripsPerVehicle: { tripsPerVehicle: number | null } | null;
                maxVehicles: {
                    maxAvailable: number;
                    maxUnavailable: number;
                    maxParked: number;
                    averageMaxAvailable: number;
                    averageMaxUnavailable: number;
                    averageMaxParked: number;
                } | null;
            }> | null;
        } | null;
    };
};

export type AggregatedUnfinalizedOperatorsArgs = Exact<{
    slug: Scalars['String'];
    period: AggregatedReportPeriod;
    date: Scalars['Date'];
}>;

export type AggregatedUnfinalizedOperatorsData = {
    mapView: {
        aggregatedOverviewReport: {
            id: string;
            aggregatedReports: Array<{
                id: string;
                finalized: boolean;
                operator: { id: string; name: OperatorName };
            }> | null;
        } | null;
    };
};

export type DailyReportAreasArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type DailyReportAreasData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            operatorId: string;
            name: OperatorName;
            report: {
                id: string;
                aoiMdsMetrics: Array<{
                    tripsPerVehicle: number | null;
                    hourlyDeploymentCounts: Array<[number, number]> | null;
                    tripEndCount: number | null;
                    tripStartCount: number | null;
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                    morningDeploymentCounts: {
                        unavailableCount: number | null;
                        availableCount: number | null;
                        parkedCount: number | null;
                    } | null;
                }> | null;
            } | null;
        }>;
    };
};

export type SingleOperatorDailyAoiDataArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    operatorId?: InputMaybe<Scalars['ID']>;
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type SingleOperatorDailyAoiDataData = {
    mapView: {
        id: string;
        operator: {
            id: string;
            report: {
                id: string;
                aoiMdsMetrics: Array<{
                    tripEndCount: number | null;
                    tripStartCount: number | null;
                    area: {
                        id: string;
                        name: string;
                        slug: string;
                        priority: number;
                        tableViewOnly: boolean;
                        isMunicipalityBoundary: boolean;
                        operatorIds: Array<string>;
                        archivedAt: string | null;
                        sharedWithOperators: boolean;
                        localCreatedDate: string;
                        modified: string;
                        operators: Array<{ id: string; name: OperatorName }>;
                    };
                    morningDeploymentCounts: {
                        unavailableCount: number | null;
                        availableCount: number | null;
                        parkedCount: number | null;
                    } | null;
                }> | null;
            } | null;
        } | null;
    };
};

export type SingleOperatorMdsDataArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    operatorId?: InputMaybe<Scalars['ID']>;
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type SingleOperatorMdsDataData = {
    mapView: {
        id: string;
        operator: {
            id: string;
            hasMdsData: boolean;
            report: {
                id: string;
                finalized: boolean;
                maxVehicleMetric: {
                    maxAvailable: number;
                    maxAvailableTime: string;
                    maxUnavailable: number;
                    maxUnavailableTime: string;
                    maxParked: number;
                    maxParkedTime: string;
                } | null;
                tripCounts: { startCount: number } | null;
                tripsPerVehicleMetric: {
                    tripsPerVehicle: number | null;
                } | null;
            } | null;
        } | null;
    };
};

export type AllOperatorsMdsDataArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type AllOperatorsMdsDataData = {
    mapView: {
        id: string;
        overviewReport: {
            id: string;
            reportDate: string;
            reports: Array<{
                id: string;
                operator: { name: OperatorName };
                tripCounts: { startCount: number } | null;
                tripsPerVehicleMetric: {
                    tripsPerVehicle: number | null;
                } | null;
                maxVehicleMetric: {
                    maxAvailable: number;
                    maxAvailableTime: string;
                    maxUnavailable: number;
                    maxUnavailableTime: string;
                    maxParked: number;
                    maxParkedTime: string;
                } | null;
            }> | null;
        } | null;
    };
};

export type DailyReportOverviewMaxVehiclesArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type DailyReportOverviewMaxVehiclesData = {
    mapView: {
        id: string;
        overviewReport: {
            id: string;
            reportDate: string;
            reports: Array<{
                id: string;
                operator: { name: OperatorName };
                maxVehicleMetric: {
                    maxAvailable: number;
                    maxAvailableTime: string;
                    maxUnavailable: number;
                    maxUnavailableTime: string;
                    maxParked: number;
                    maxParkedTime: string;
                } | null;
            }> | null;
        } | null;
    };
};

export type DailyOverviewAreasArgs = Exact<{
    slug: Scalars['String'];
    date: Scalars['Date'];
    vehicleClass?: InputMaybe<VehicleClass>;
}>;

export type DailyOverviewAreasData = {
    mapView: {
        id: string;
        overviewReport: {
            id: string;
            reportDate: string;
            aoiMdsTotalsMetric: Array<{
                tripStartCount: number;
                tripEndCount: number;
                morningDeploymentAvailableCount: number | null;
                tripsPerVehicle: number | null;
                area: {
                    operatorIds: Array<string>;
                    id: string;
                    name: string;
                    slug: string;
                    priority: number;
                    tableViewOnly: boolean;
                    isMunicipalityBoundary: boolean;
                    archivedAt: string | null;
                    sharedWithOperators: boolean;
                    localCreatedDate: string;
                    modified: string;
                    operators: Array<{ id: string; name: OperatorName }>;
                };
            }> | null;
        } | null;
    };
};

export type DailyFinalizedReportsArgs = Exact<{
    slug: Scalars['String'];
    date: Scalars['Date'];
}>;

export type DailyFinalizedReportsData = {
    mapView: {
        id: string;
        overviewReport: {
            id: string;
            reports: Array<{
                id: string;
                finalized: boolean;
                operator: { id: string; name: OperatorName };
            }> | null;
        } | null;
    };
};

export type AggregatedReportsListArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    period: AggregatedReportPeriod;
}>;

export type AggregatedReportsListData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            aggregatedReports: Array<{
                id: string;
                reportDate: string;
                finalized: boolean;
            }>;
        }>;
    };
};

export type ReportsListArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
}>;

export type ReportsListData = {
    mapView: {
        id: string;
        operators: Array<{
            id: string;
            name: OperatorName;
            reports: Array<{
                id: string;
                reportDate: string;
                finalized: boolean;
            }>;
        }>;
    };
};

export type EditSegmentMutationArgs = Exact<{
    streetSegment: EditStreetSegmentInput;
}>;

export type EditSegmentResult = {
    editStreetSegment: {
        streetSegment: { geometryId: string; name: string } | null;
    } | null;
};

export type CurrentUserArgs = Exact<{ [key: string]: never }>;

export type CurrentUserData = {
    user: {
        id: number;
        isStaff: boolean;
        enabledFeatures: Array<FeatureFlaggedFeature>;
        hasAcceptedTerms: boolean;
        email: string;
        orgAuth: {
            id: string;
            role: UserRoleType;
            organization:
                | {
                      __typename: 'AgencyOrganization';
                      customerStage: CustomerStageType | null;
                      operatorStage: OperatorStageType | null;
                      id: string;
                      name: string;
                      locale: string;
                      mapViews: Array<{
                          id: string;
                          name: string;
                          slug: string;
                          hasAtLeastOneMdsFeed: boolean;
                      }>;
                  }
                | {
                      __typename: 'OperatorOrganization';
                      customerStage: CustomerStageType | null;
                      operatorStage: OperatorStageType | null;
                      id: string;
                      name: string;
                      locale: string;
                      operatorInfo: { id: string; name: OperatorName };
                      mapViews: Array<{
                          id: string;
                          name: string;
                          slug: string;
                          hasAtLeastOneMdsFeed: boolean;
                      }>;
                  };
        } | null;
    };
};

export type MapViewOverviewArgs = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type MapViewOverviewData = {
    mapView: {
        id: string;
        name: string;
        slug: string;
        ianaTimezone: string;
        initialViewBounds: [number, number, number, number];
        maxBounds: [number, number, number, number];
        hasFees: boolean | null;
        measurementSystem: MeasurementUnitSystemType;
        enabledFeatures: Array<FeatureFlaggedFeature>;
        forOperator: { id: string; slug: string; name: OperatorName } | null;
        operators: Array<{
            id: string;
            operatorId: string;
            hasMdsData: boolean;
            name: OperatorName;
            info: { id: string; name: OperatorName; slug: string };
        }>;
        activeSources: Array<{
            id: string;
            info: { id: string; name: OperatorName; slug: string };
        }>;
    };
};

export type MapViewOperatorFragment = {
    id: string;
    operatorId: string;
    hasMdsData: boolean;
    name: OperatorName;
    info: { id: string; name: OperatorName; slug: string };
};

export type MapViewOperatorInfoFragment = {
    id: string;
    name: OperatorName;
    slug: string;
};

export type AcceptTermsMutationArgs = Exact<{
    hasAcceptedTerms: Scalars['Boolean'];
}>;

export type AcceptTermsResult = {
    acceptTerms: {
        user: { id: number; hasAcceptedTerms: boolean } | null;
    } | null;
};

export type AddUserMutationArgs = Exact<{
    email: Scalars['String'];
    role: UserRoleType;
}>;

export type AddUserResult = {
    addUser: {
        userOrgAuth: { id: string; email: string; role: UserRoleType } | null;
    } | null;
};

export type DeleteUserMutationArgs = Exact<{
    email: Scalars['String'];
}>;

export type DeleteUserResult = {
    deleteUserAuthorization: { userEmail: string | null } | null;
};

export type UserListInfoFragment = {
    id: string;
    email: string;
    role: UserRoleType;
};

export type ChangeUserRoleMutationArgs = Exact<{
    email: Scalars['String'];
    role: UserRoleType;
}>;

export type ChangeUserRoleResult = {
    changeUserRole: {
        userOrgAuth: { id: string; email: string; role: UserRoleType } | null;
    } | null;
};

export type UsersListArgs = Exact<{
    orgId: Scalars['ID'];
}>;

export type UsersListData = {
    organization:
        | {
              id: string;
              users: Array<{ id: string; email: string; role: UserRoleType }>;
          }
        | {
              id: string;
              users: Array<{ id: string; email: string; role: UserRoleType }>;
          };
};
