import { gql } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import { useReloadingData } from 'utils/use-data';
import { RideshedMetadata } from 'page-explore/page-heatmaps/ridesheds/reducer';
import { useMemo } from 'react';
import { ReferenceCounts } from '../routes/api';
import {
    AreaRideshedArgs,
    AreaRideshedData,
    AreaRideshedsInfoArgs,
    AreaRideshedsInfoData
} from 'graphql.g';

export interface RideshedData {
    referenceCounts: ReferenceCounts;
    totalTripCount: number;
    matchedTripCount: number;
}

interface RideshedParams {
    areaRideshedId: string | null;
}

const AREA_RIDESHED_QUERY = gql`
    query AreaRideshed($slug: String!, $areaRideshedId: ID!) {
        mapView(slug: $slug) {
            id
            areaRideshed(areaRideshedId: $areaRideshedId) {
                referenceCounts
                totalTripCount
                matchedTripCount
            }
        }
    }
`;

const AREA_RIDESHEDS_INFO_QUERY = gql`
    query AreaRideshedsInfo($slug: String!) {
        mapView(slug: $slug) {
            id
            areaRidesheds {
                id
                name
                startDate
                endDate
                startTime
                endTime
                days
                status
                areas {
                    id
                    name
                    slug
                    geometryJson
                }
                operators {
                    id
                    name
                }
                vehicleClasses
                type
            }
        }
    }
`;

export enum AreaRideshedType {
    startsInArea = 'starts_in_area',
    endsInArea = 'ends_in_area',
    startsOrEndsInArea = 'starts_or_ends_in_area',
    aToB = 'a_to_b',
    bidirectional = 'bidirectional'
}

/**
 * Fetches the routes heatmap data for the selected area rideshed.
 * All filtering is done on the server side, and filters are only
 * displayed staticly.
 */
export function useAreaRideshed(
    args: RideshedParams
): [RideshedData | null, boolean] {
    const { slug } = useMapView();

    const [areaRideshed, isLoading] = useReloadingData<
        AreaRideshedData,
        AreaRideshedArgs
    >(
        AREA_RIDESHED_QUERY,
        args.areaRideshedId === null
            ? 'skip'
            : {
                  slug,
                  areaRideshedId: args.areaRideshedId
              }
    );

    const parsedAreaRideshed = useMemo(() => {
        if (
            areaRideshed === null ||
            areaRideshed.mapView.areaRideshed === null
        ) {
            return null;
        }

        const referenceCounts: ReferenceCounts = JSON.parse(
            areaRideshed.mapView.areaRideshed.referenceCounts
        );

        const {
            totalTripCount,
            matchedTripCount
        } = areaRideshed.mapView.areaRideshed;

        return {
            referenceCounts,
            totalTripCount,
            matchedTripCount
        };
    }, [areaRideshed]);

    return [parsedAreaRideshed, isLoading];
}

export function useAreaRideshedsInfo(): [RideshedMetadata[], boolean] {
    const { slug } = useMapView();

    const [areaRidesheds, isLoading] = useReloadingData<
        AreaRideshedsInfoData,
        AreaRideshedsInfoArgs
    >(AREA_RIDESHEDS_INFO_QUERY, {
        slug
    });

    const parsedAreaRidesheds = useMemo(() => {
        if (
            areaRidesheds === null ||
            areaRidesheds.mapView.areaRidesheds === null
        ) {
            return [];
        }

        const areaRideshedsData = areaRidesheds.mapView
            .areaRidesheds as RideshedMetadata[];

        return areaRideshedsData;
    }, [areaRidesheds]);

    return [parsedAreaRidesheds !== null ? parsedAreaRidesheds : [], isLoading];
}
