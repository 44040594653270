import React from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/ridesheds/rideshedMetadataPanel.tsx?resplendence=true";

import {
    defineMessages,
    FormattedMessage,
    FormattedTime,
    useIntl
} from 'react-intl';

import { RideshedMetadata } from './reducer';
import TotalCount from 'page-explore/common/total-count';
import { findAverageDailyTrips } from 'page-explore/layers/ridesheds/popup';
import { DateRange } from 'common/date-picker';
import { LocalDate } from 'utils/date-tools';
import { TabToggle } from 'common/layout';
import KeyValue from 'common/key-value';
import { dayStrings, getDayOfWeekType } from 'page-policy/policy';
import { separatedBy } from 'utils/helpers';
import { OperatorTag } from 'common/tag';
import { getDateTime } from 'page-policy/util';
import Icon from 'common/icon';
import { ICON_FOR_VEHICLE_CLASS } from 'common/vehicle-classes';
import {
    AreaRideshedType,
    RideshedData
} from 'page-explore/layers/ridesheds/api';

/*
@import 'style.scss';
*/;

const AREA_RIDESHED_METADATA = "rx-page-explore-page-heatmaps-ridesheds-rideshedMetadataPanel-1"/*
    display: flex;
    flex-direction: column;
    gap: 16rem;
*/;

export type CountDisplayFormat = 'Total Count' | 'Avg. Trips';

interface RideshedMetadataPanelProps {
    areaRideshedInfo: RideshedMetadata;
    areaRideshedData: RideshedData;
    countDisplayFormat: CountDisplayFormat;
    setCountDisplayFormat: (value: CountDisplayFormat) => void;
}

const messages = defineMessages({
    'total-count': 'Total Count',
    'avg-trips': 'Avg. Trips'
});

const singleAreaRideshedTypes = [
    AreaRideshedType.startsInArea,
    AreaRideshedType.endsInArea,
    AreaRideshedType.startsOrEndsInArea
];
const multiAreaRideshedTypes = [
    AreaRideshedType.aToB,
    AreaRideshedType.bidirectional
];

const RideshedMetadataPanel = ({
    areaRideshedInfo,
    areaRideshedData,
    countDisplayFormat,
    setCountDisplayFormat
}: RideshedMetadataPanelProps) => {
    const intl = useIntl();

    const dataPeriod: DateRange = [
        LocalDate.fromDateString(areaRideshedInfo.startDate),
        LocalDate.fromDateString(areaRideshedInfo.endDate)
    ];
    const matchedTripCount = areaRideshedData.matchedTripCount;
    const avgMatchedTripCount =
        matchedTripCount && findAverageDailyTrips(dataPeriod, matchedTripCount);

    const dayOfWeekType = getDayOfWeekType(areaRideshedInfo.days);

    return (
        <div className={AREA_RIDESHED_METADATA}>
            {singleAreaRideshedTypes.includes(areaRideshedInfo.type) &&
            areaRideshedInfo.areas.length > 0 ? (
                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="rideshed-area-label"
                            defaultMessage="Area"
                        />
                    }
                >
                    {areaRideshedInfo.areas[0].name}
                </KeyValue>
            ) : (
                multiAreaRideshedTypes.includes(areaRideshedInfo.type) &&
                areaRideshedInfo.areas.length > 1 && (
                    <div>
                        <KeyValue
                            keyName={
                                <FormattedMessage
                                    key="rideshed-area-a-label"
                                    defaultMessage="Area A"
                                />
                            }
                        >
                            {areaRideshedInfo.areas[0].name}
                        </KeyValue>
                        <KeyValue
                            keyName={
                                <FormattedMessage
                                    key="rideshed-area-b-label"
                                    defaultMessage="Area B"
                                />
                            }
                        >
                            {areaRideshedInfo.areas[1].name}
                        </KeyValue>
                    </div>
                )
            )}
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="rideshed-type-label"
                        defaultMessage="Type"
                    />
                }
            >
                {areaRideshedInfo.type === AreaRideshedType.startsInArea ? (
                    <FormattedMessage
                        key="area-rideshed-type-starts-in-area"
                        defaultMessage="Trips Starting in Area"
                    />
                ) : areaRideshedInfo.type === AreaRideshedType.endsInArea ? (
                    <FormattedMessage
                        key="area-rideshed-type-ends-in-area"
                        defaultMessage="Trips Ending in Area"
                    />
                ) : areaRideshedInfo.type ===
                  AreaRideshedType.startsOrEndsInArea ? (
                    <FormattedMessage
                        key="area-rideshed-type-starts-or-ends-in-area"
                        defaultMessage="Trips Starting or Ending in Area"
                    />
                ) : areaRideshedInfo.type === AreaRideshedType.aToB ? (
                    <FormattedMessage
                        key="area-rideshed-type-a-to-b"
                        defaultMessage="Trips Traveling from Area A to Area B"
                    />
                ) : areaRideshedInfo.type === AreaRideshedType.bidirectional ? (
                    <FormattedMessage
                        key="area-rideshed-type-bidirectional"
                        defaultMessage="Trips Traveling between Areas A and B"
                    />
                ) : (
                    <FormattedMessage
                        key="area-rideshed-type-unknown"
                        defaultMessage="Unknown"
                    />
                )}
            </KeyValue>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="rideshed-dates-label"
                        defaultMessage="Dates"
                    />
                }
            >
                {areaRideshedInfo.startDate} - {areaRideshedInfo.endDate}
            </KeyValue>
            {areaRideshedInfo.startTime && (
                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="policy-rule-time-label"
                            defaultMessage="Time"
                        />
                    }
                >
                    <FormattedTime
                        value={getDateTime(areaRideshedInfo.startTime)}
                    />
                    {/* if we have an end time, and it differs from our start time, show the range */}
                    {areaRideshedInfo.endTime &&
                        areaRideshedInfo.endTime !==
                            areaRideshedInfo.startTime && (
                            <>
                                {' '}
                                -{' '}
                                <FormattedTime
                                    value={getDateTime(
                                        areaRideshedInfo.endTime
                                    )}
                                />
                            </>
                        )}
                </KeyValue>
            )}

            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-rule-day-of-week-label"
                        defaultMessage="Day of Week"
                    />
                }
            >
                {dayOfWeekType === 'all' ? (
                    <FormattedMessage
                        key="day-of-week-all"
                        defaultMessage="All"
                    />
                ) : dayOfWeekType === 'weekend' ? (
                    <FormattedMessage
                        key="day-of-week-weekend"
                        defaultMessage="Weekends"
                    />
                ) : dayOfWeekType === 'weekdays' ? (
                    <FormattedMessage
                        key="day-of-week-weekdays"
                        defaultMessage="Weekdays"
                    />
                ) : (
                    areaRideshedInfo.days
                        .map(day => intl.formatMessage(dayStrings[day]))
                        .join(', ')
                )}
            </KeyValue>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-providers-label"
                        defaultMessage="Providers"
                    />
                }
            >
                {separatedBy(
                    areaRideshedInfo.operators.map(operator => (
                        <OperatorTag key={operator.id} name={operator.name} />
                    )),
                    ' '
                )}
            </KeyValue>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-rule-vehicle-classes-label"
                        defaultMessage="Vehicle Class"
                    />
                }
            >
                {areaRideshedInfo.vehicleClasses.length === 0 ? (
                    <FormattedMessage
                        key="vehicle-classes-all"
                        defaultMessage="All"
                    />
                ) : (
                    separatedBy(
                        areaRideshedInfo.vehicleClasses.map(vc => (
                            <Icon key={vc} icon={ICON_FOR_VEHICLE_CLASS[vc]} />
                        )),
                        ' '
                    )
                )}
            </KeyValue>
            <TabToggle
                label={
                    <FormattedMessage
                        key="select-count-type-label"
                        defaultMessage="Count Type"
                    />
                }
                tabLabels={[
                    intl.formatMessage(messages['total-count']),
                    intl.formatMessage(messages['avg-trips'])
                ]}
                checked={countDisplayFormat === 'Avg. Trips'}
                onChange={value => {
                    setCountDisplayFormat(value ? 'Avg. Trips' : 'Total Count');
                }}
            />
            <TotalCount
                count={
                    countDisplayFormat === 'Total Count'
                        ? matchedTripCount
                        : avgMatchedTripCount
                }
            >
                {countDisplayFormat === 'Total Count' ? (
                    <FormattedMessage
                        key="matched-trips"
                        defaultMessage="Matched Trips"
                    />
                ) : (
                    <FormattedMessage
                        key="daily-average-trips"
                        defaultMessage="Daily Avg. Trips"
                    />
                )}
            </TotalCount>
        </div>
    );
};

export default RideshedMetadataPanel;
