/**
 * All operators colors and whether or not we display black text on their
 * brand color.
 *
 * Please don't access this directly, use getOperatorColorStyle instead,
 * since it will handle reporting missing operators
 */
export const OPERATOR_COLORS = {
    Ario: { background: '#FCCA00', blackText: true },
    'Blue Duck': { background: '#245185', blackText: false },
    'Citi Bike': { background: '#263571', blackText: false },
    'Ford GoBike': { background: '#013377', blackText: false },
    'Indigo Weel': { background: '#a3007a', blackText: false },
    'Revel Transit': { background: '#139deb', blackText: false },
    'Share Now': { background: '#005478', blackText: false },
    Bcycle: { background: '#000000', blackText: false },
    Beam: { background: '#5022FF', blackText: false },
    Biketown: { background: '#FC4C02', blackText: false },
    Bird: { background: '#63CAEE', blackText: true },
    'Bird San Francisco': { background: '#63CAEE', blackText: true },
    'Bird Oakland': { background: '#63CAEE', blackText: true },
    Bolt: { background: '#FFB700', blackText: true },
    Charge: { background: '#E34C3C', blackText: false },
    Circ: { background: '#FF5F00', blackText: false },
    Dott: { background: '#282828', blackText: false },
    Flamingo: { background: '#FF206E', blackText: false },
    Free2Move: { background: '#0196D8', blackText: true },
    Gotcha: { background: '#2CECE2', blackText: true },
    Gruv: { background: '#0071CF', blackText: false },
    Helbiz: { background: '#000000', blackText: false },
    HelloRide: { background: '#0874fc', blackText: true },
    Hive: { background: '#DCFF00', blackText: true },
    HOPR: { background: '#42DEEA', blackText: true },
    Jump: { background: '#FF0A2D', blackText: false },
    Lime: { background: '#00DD00', blackText: true },
    'Lime Arvada': { background: '#00DD00', blackText: true },
    'Lime Boulder': { background: '#00DD00', blackText: true },
    'Lime Denver': { background: '#00DD00', blackText: true },
    'Lime Oakland-Berkeley': { background: '#00DD00', blackText: true },
    'Lime San Jose': { background: '#00DD00', blackText: true },
    Lyft: { background: '#FF00BF', blackText: false },
    Lynx: { background: '#F2420C', blackText: false },
    Neuron: { background: '#F16222', blackText: false },
    nextbike: { background: '#004A99', blackText: false },
    OGO: { background: '#6AC437', blackText: true },
    Ojo: { background: '#AB1E2C', blackText: false },
    Onzo: { background: '#F3C725', blackText: true },
    Pace: { background: '#141dea', blackText: false },
    Pony: { background: '#321E65', blackText: false },
    Razor: { background: '#FD0001', blackText: false },
    Revel: { background: '#00AEEF', blackText: false },
    Roll: { background: '#FFF200', blackText: true },
    Scoobi: { background: '#143557', blackText: false },
    Scoot: { background: '#FD4452', blackText: false },
    Scougar: { background: '#F1B38C', blackText: true },
    Shared: { background: '#FF0000', blackText: false },
    Sherpa: { background: '#000000', blackText: false },
    Skip: { background: '#1E9ED7', blackText: false },
    Spin: { background: '#FF5436', blackText: false },
    'Spin Oakland-Berkeley': { background: '#FF5436', blackText: false },
    'Spin San Jose': { background: '#FF5436', blackText: false },
    Superpedestrian: { background: '#221F20', blackText: false },
    Taxify: { background: '#fbe551', blackText: false },
    Tayo: { background: '#6118AF', blackText: false },
    Tier: { background: '#69D2AA', blackText: true },
    Uscooters: { background: '#fcb040', blackText: false },
    Veo: { background: '#00B8AA', blackText: true },
    'Veo Emeryville': { background: '#00B8AA', blackText: true },
    'Veo San Jose': { background: '#00B8AA', blackText: true },
    'Veo Santa Clara': { background: '#00B8AA', blackText: true },
    Voi: { background: '#F46C62', blackText: true },
    Wheels: { background: '#EA174E', blackText: false },
    Wind: { background: '#008AA1', blackText: false }
    // please keep these alphabetically sorted :)
};

export type OperatorName = keyof typeof OPERATOR_COLORS;

export const OPERATOR_NAMES: OperatorName[] = Object.keys(
    OPERATOR_COLORS
) as OperatorName[];
