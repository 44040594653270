import React, { useReducer, useState } from 'react';
import { MapOptionsAction, MapOptions } from 'page-explore';
import "/opt/build/repo/src/page-explore/page-heatmaps/ridesheds/index.tsx?resplendence=true";

import { Map } from 'mapbox-gl';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { OVERLAY } from '../../common/overlay';
import ModePicker from '../../common/mode-picker';

import { rideshedInitialState, rideshedReducer } from './reducer';
import { useRideshedEffects } from './effects';
import { Select } from 'common/select';
import { findAverageDailyTrips } from 'page-explore/layers/ridesheds/popup';
import { DateRange } from 'common/date-picker';
import { LocalDate } from 'utils/date-tools';
import RoutesSidebar from '../routes/sidebar';
import { AggregatedRouteFeature } from 'page-explore/layers/routes/sources';
import HeatmapKey from 'page-explore/common/heatmap-key';
import { RIDESHEDS_SOURCE_KEY } from 'page-explore/layers/routes/layers';
import { useQueryState } from 'router';
import RideshedMetadataPanel, {
    CountDisplayFormat
} from './rideshedMetadataPanel';

/*
@import 'style.scss';
*/;

const SELECT_WRAPPER = "rx-page-explore-page-heatmaps-ridesheds-index-1"/*
    display: grid;
*/;

interface MapRideshedProps {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
}

const MapRidesheds = ({
    map,
    mapOptions,
    dispatchMapOptions
}: MapRideshedProps) => {
    const [rideshedState, rideshedDispatch] = useReducer(
        rideshedReducer,
        rideshedInitialState
    );

    const effects = useRideshedEffects(
        map,
        dispatchMapOptions,
        rideshedState,
        rideshedDispatch
    );

    const [areaRideshedId, setAreaRideshedId] = useQueryState<string | null>(
        'rideshedId' as any,
        {
            active: true,
            addToHistory: true
        }
    );

    // if we're loading this page for the first time from a link with a rideshedId, we need to set the selectedAreaRideshedInfo
    // using the rideshedId from the query params. If not, we should select the first completed rideshed in the list.
    const selectedAreaRideshedInfo =
        rideshedState.areaRideshedsInfo.find(
            rideshed => rideshed.id === areaRideshedId
        ) ||
        rideshedState.areaRideshedsInfo.find(
            rideshed => rideshed.status === 'completed'
        );

    if (selectedAreaRideshedInfo && !rideshedState.selectedAreaRideshedInfo) {
        setAreaRideshedId(selectedAreaRideshedInfo.id);
        rideshedDispatch({
            type: 'SET_SELECTED_AREA_RIDESHED_INFO',
            payload: {
                selectedAreaRideshedInfo: selectedAreaRideshedInfo
            }
        });
    }

    const dataPeriod: DateRange = [
        LocalDate.fromDateString(
            rideshedState.selectedAreaRideshedInfo?.startDate ||
                LocalDate.todayUtc().toString()
        ),
        LocalDate.fromDateString(
            rideshedState.selectedAreaRideshedInfo?.endDate ||
                LocalDate.todayUtc().toString()
        )
    ];

    const [countDisplayFormat, setCountDisplayFormat] = useState<
        CountDisplayFormat
    >('Total Count');

    const legendScale = effects.dataSteps
        ? effects.dataSteps.map(({ value, color }) => ({
              value: (
                  <FormattedNumber
                      value={
                          countDisplayFormat === 'Total Count'
                              ? value
                              : findAverageDailyTrips(dataPeriod, value)
                      }
                  />
              ),
              color
          }))
        : null;

    return (
        <>
            <div className={OVERLAY}>
                <ModePicker
                    options={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                >
                    <div className={SELECT_WRAPPER}>
                        <label>
                            <FormattedMessage
                                key="select-ridesheds-label"
                                defaultMessage="Ridesheds"
                            />
                        </label>
                        <Select
                            value={{
                                value: areaRideshedId,
                                label: rideshedState.areaRideshedsInfo.find(
                                    rideshed => rideshed.id === areaRideshedId
                                )?.name
                            }}
                            onChange={option => {
                                setAreaRideshedId(option?.value || null);
                                const selectedAreaRideshedInfo = rideshedState.areaRideshedsInfo.find(
                                    rideshed => rideshed.id === option?.value
                                );
                                selectedAreaRideshedInfo &&
                                    rideshedDispatch({
                                        type: 'SET_SELECTED_AREA_RIDESHED_INFO',
                                        payload: {
                                            selectedAreaRideshedInfo: selectedAreaRideshedInfo
                                        }
                                    });
                            }}
                            options={
                                rideshedState.areaRideshedsInfo
                                    .filter(
                                        rideshed =>
                                            rideshed.status === 'completed'
                                    )
                                    .map(rideshed => ({
                                        value: rideshed.id,
                                        label: rideshed.name
                                    })) || []
                            }
                        />
                    </div>
                    {rideshedState.selectedAreaRideshedInfo && (
                        <RideshedMetadataPanel
                            areaRideshedInfo={
                                rideshedState.selectedAreaRideshedInfo
                            }
                            areaRideshedData={
                                rideshedState.selectedAreaRideshedData
                            }
                            countDisplayFormat={countDisplayFormat}
                            setCountDisplayFormat={setCountDisplayFormat}
                        />
                    )}
                </ModePicker>
                <RoutesSidebar
                    mapOptions={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                    dateRange={dataPeriod}
                    routesGeoJSONData={
                        // mapbox & typescript aren't playing nice
                        (effects.sources[RIDESHEDS_SOURCE_KEY] as any).data
                            .features as AggregatedRouteFeature[]
                    }
                />
                {legendScale ? <HeatmapKey scale={legendScale} /> : null}
            </div>
            <div style={{ display: 'none' }}>{effects.popup}</div>
        </>
    );
};

export default MapRidesheds;
