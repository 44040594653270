import { Sources } from 'mapbox-gl';
import { useMemo } from 'react';
import { RideshedData } from './api';
import { StreetSegments } from '../routes/api';
import { RIDESHEDS_SOURCE_KEY } from '../routes/layers';

export function useRideshedSources(
    streetSegments: StreetSegments | null,
    ridesheds: RideshedData | null
): Sources {
    return useMemo(() => {
        return {
            [RIDESHEDS_SOURCE_KEY]: {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: composeRideshedFeatures(streetSegments, ridesheds)
                } as GeoJSON.FeatureCollection<GeoJSON.LineString>
            }
        };
    }, [streetSegments, ridesheds]);
}

export type RideshedFeature = GeoJSON.Feature<
    GeoJSON.LineString,
    {
        id: string;
        name: string;
        count: number;
        percentage: number;
        geometryId: string;
    }
>;

/**
 * Builds the GeoJSON rideshed data by combining our street segments with the
 * selected rideshed's data. Results in an array of GeoJSON features that can
 * be supplied to mapbox to display.
 */
const composeRideshedFeatures = (
    streetSegments: StreetSegments | null,
    aggregatedTripRoutes: RideshedData | null
): RideshedFeature[] => {
    if (streetSegments == null || aggregatedTripRoutes == null) {
        return [];
    }

    if (Object.keys(streetSegments).length === 0) {
        const noStreetSegmentsError = new Error(`no street segments found.`);
        if (window.rollbar) {
            window.rollbar.warn(
                noStreetSegmentsError.message,
                noStreetSegmentsError
            );
        }

        return [];
    }

    const missingSegmentIds: Array<string> = [];
    const features = Object.entries(aggregatedTripRoutes.referenceCounts)
        .map(([geometryId, data]) => {
            const segment = streetSegments[geometryId];

            if (segment == null) {
                missingSegmentIds.push(geometryId);
                return undefined;
            }

            return {
                type: 'Feature',
                properties: {
                    id: geometryId,
                    name: segment.name,
                    roadClass: segment.road_class,
                    count: data.count,
                    percentage: data.percentageMatched,
                    geometryId
                },
                geometry: segment.geometry
            };
        })
        .filter(Boolean) // remove any empty values
        .sort((a, b) => {
            if (a == null || b == null) {
                return 0;
            }
            // we need to sort so that segments with higher counts appear
            // 'above' those with lower counts. This makes overlapping
            // segments abide by our sense of hierarchy
            return a.properties.count - b.properties.count;
        }) as RideshedFeature[];

    if (missingSegmentIds) {
        // this shouldn't be undefined, but it can happen because the
        // result ids don't have dashes, such as
        // 00d19fa723708e4a61e0380246d75a7 vs.
        // 00d19fa4-7237-08e4-a61e-0380246d75a7

        // note, we collect these segments and report to rollbar once
        // as this can significantly slow the loading of the page should
        // many segments be missing.
        if (window.rollbar) {
            window.rollbar.info(
                'Missing street segments for ridesheds. ',
                `Segments with route results but missing from segment response were: ${missingSegmentIds}`
            );
        }
    }
    return features;
};
